<template>
  <page-container title="保养项目" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="设备类别" prop="liftpropertyid">
              <a-select v-model="queryParams.liftpropertyid">
                <a-select-option value="">请选择</a-select-option>
                <a-select-option v-for="(item, index) in liftpropertyidOptions" :key="index" :value="item.liftpropertyid">{{item.value}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="保养类型" prop="maintenancescheduletype">
              <a-select v-model="queryParams.maintenancescheduletype">
                <a-select-option value="">请选择</a-select-option>
                <a-select-option v-for="(item, index) in maintenanceTypes" :key="index" :value="item.value">{{item.name}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <div class="query-btns">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>
            </div>
          </a-form-model>
        </div>
        <div v-show="showAdvanced" class="advanced-query">
          <a-form-model ref="advancedForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="项目名称" prop="itemname">
              <a-input v-model.trim="queryParams.itemname" placeholder="请输入"></a-input>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">保养项目</h3>
          <div class="table-btns">
            <a-button type="primary" @click="add"><a-icon type="plus"></a-icon>新建</a-button>
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData(true)"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" row-key="maintenanceitemid" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="operation" slot-scope="value, record, index">
            <a-button type="link" size="small" @click="modify(value, record, index)">修改</a-button>
            <a-button type="link" size="small" @click="deleteConfirm(value, record, index)">删除</a-button>
          </span>
          <span slot="maintenancescheduletype" slot-scope="value">
            <span>{{maintenanceTypesMap[value]||''}}</span>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <add-or-edit-modal :visible.sync="modalVisible" :show-type="modalShowType" :detailData="modalDetailData" @get-operation-result="getTableData"></add-or-edit-modal>
  </page-container>
</template>

<script>
import { getItemFromArrayByKey } from 'U'
import store from '@/store'
import pagination from '@/mixins/pagination'
import maintenanceTypes from '@/json/maintenanceTypes'
import { getLiftListByCondition } from 'A/jcgn.js'
import { findMaintenanceitemListByCondition, deleteMaintenanceitem } from 'A/wbgl.js'
import AddOrEditModal from './AddOrEditModal'
import callCenter from "C/modals/callCenter";

export default {
  name: 'maintenanceItem',
  mixins: [pagination],
  components: {
    AddOrEditModal,
  },
  data() {
    return {
      showAdvanced: false,
      maintenanceTypes,
      breadcrumb: [
        {
          name: '维保管理',
          path: ''
        },
        {
          name: '保养管理',
          path: ''
        },
        {
          name: '保养项目',
          path: ''
        },
      ],
      liftpropertyidOptions: [],
      queryParams: {
        liftpropertyid: '',
        maintenancordertype: '2',
        maintenancescheduletype: '',
        itemname: '',
      },
      tableColumns: [
        {
          title: '序号',
          dataIndex: 'sequence',
          key: 'sequence',
          width: 80,
          align: 'center',
        },
        {
          title: '项目名称',
          dataIndex: 'itemname',
          key: 'itemname',
          ellipsis: true,
        },
        {
          title: '保养要求',
          dataIndex: 'itemdesc',
          key: 'itemdesc',
          ellipsis: true,
        },
        {
          title: '保养类型',
          dataIndex: 'maintenancescheduletype',
          key: 'maintenancescheduletype',
          ellipsis: true,
          scopedSlots: { customRender: 'maintenancescheduletype' }
        },
        {
          title: '设备类别',
          dataIndex: 'liftpropertyvalue',
          key: 'liftpropertyvalue',
          ellipsis: true,
        },
        {
          title: '操作',
          key: 'operation',
          scopedSlots: { customRender: 'operation' }
        },
      ],
      tableData: [],
      tableLoading: false,
      modalShowType: 'add',
      modalVisible: false,
      modalDetailData: null,
      counts:'',
    }
  },
  computed: {
    maintenanceTypesMap() {
      let result = {};
      this.maintenanceTypes.forEach(item => {
        result[item.value] = item.name;
      })
      return result;
    }
  },
  beforeRouteEnter(to, from, next) {
    let toTab = getItemFromArrayByKey(store.state.main.tabs, 'id', to.name);
    if(!toTab) {
      store.commit('delKeepAlive', to.name)
    }
    next()
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getTableData();
      this.initLiftpropertyidOptions();
    },
    initLiftpropertyidOptions() {
      let params = {
        liftpropertytype: '1',
      }
      getLiftListByCondition(params).then(res => {
        if(res && res.returncode == '0') {
          this.liftpropertyidOptions = res.item;
        }
      })
    },
    resetQueryParams() {
      this.$refs.queryForm.resetFields();
      this.$refs.advancedForm.resetFields();
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      findMaintenanceitemListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    add() {
      this.modalShowType = 'add';
      this.modalDetailData = null;
      this.modalVisible = true;
    },
    modify(value, record) {
      this.modalShowType = 'edit';
      this.modalDetailData = record;
      this.modalVisible = true;
    },
    deleteConfirm(value, record) {
      this.$confirm({
        // title: '',
        content: '确定要删除该数据?',
        okText: '确定',
        cancelText: '取消',
        onOk:() => {
          this.delete(record.maintenanceitemid);
        },
        onCancel() {},
      });
    },
    delete(maintenanceitemid) {
      this.showLoading();
      if(maintenanceitemid) {
        let params = {
          maintenanceitemid
        };
        deleteMaintenanceitem(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
  }
}
</script>

<style lang="scss" scoped>
</style>