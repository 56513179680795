<template>
  <a-modal v-model="modalVisible" :title="modalTitle" :width="660" :centered="true">
    <template slot="footer">
      <a-button v-if="showType=='add'||showType=='edit'" @click="modalVisible=false">取消</a-button>
      <a-button type="primary" @click="confirm">确认</a-button>
    </template>
    <a-form-model ref="modalForm" :model="formDatas" :rules="formRules" :label-col="{span:7}" :wrapper-col="{span:14}">
      <a-form-model-item label="设备类别" prop="liftpropertyid">
        <a-select v-model="formDatas.liftpropertyid">
          <a-select-option v-for="(item, index) in liftpropertyidOptions" :key="index" :value="item.liftpropertyid">{{item.value}}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="保养类型" prop="maintenancescheduletype">
        <a-select v-model="formDatas.maintenancescheduletype" :disabeld="showType=='detail'">
          <a-select-option v-for="(item, index) in maintenanceTypes" :key="index" :value="item.value">{{item.name}}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="序号" prop="sequence">
        <a-input-number v-model.trim="formDatas.sequence" :disabeld="showType=='detail'" />
      </a-form-model-item>
      <a-form-model-item label="项目名称" prop="itemname">
        <a-input v-model.trim="formDatas.itemname" :disabeld="showType=='detail'" />
      </a-form-model-item>
      <a-form-model-item label="项目关键字" prop="itemkey">
        <a-input v-model.trim="formDatas.itemkey" :disabeld="showType=='detail'" />
      </a-form-model-item>
      <a-form-model-item label="隐患级别" prop="itemlevel">
        <a-radio-group v-model="formDatas.itemlevel" :disabeld="showType=='detail'">
          <a-radio :value="1">一般隐患</a-radio>
          <a-radio :value="2">严重隐患</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="项目要求" prop="itemdesc">
        <a-input v-model.trim="formDatas.itemdesc" :disabeld="showType=='detail'" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import maintenanceTypes from '@/json/maintenanceTypes'
import { getLiftListByCondition } from 'A/jcgn.js'
import { addMaintenanceitem, modifyMaintenanceitem } from 'A/wbgl.js'
export default {
  props: {
    visible: {
      default: false
    },
    showType: {
      default: 'add'
    },
    detailData: {
      default: null
    }
  },
  data() {
    return {
      maintenanceTypes,
      liftpropertyidOptions: [],
      modalVisible: false,
      formDatas: {
        maintenanceitemid: '',
        liftpropertyid: '',
        maintenancordertype: '2',
        maintenancescheduletype: '',
        sequence: '',
        itemname: '',
        itemkey: '',
        itemlevel: 1,
        itemdesc: '',
      },
      formRules: {
        liftpropertyid: [{required: true, message: '请选择设备类别'}],
        maintenancescheduletype: [{required: true, message: '请选择保养类型'}],
        sequence: [{required: true, message: '请输入序号'}],
        itemname: [{required: true, message: '请输入项目名称'}],
        itemkey: [{required: true, message: '请输入项目关键字'}],
        itemlevel: [{required: true, message: '请选择隐患级别'}],
        itemdesc: [{required: true, message: '请输入项目要求'}],
      }
    }
  },
  computed: {
    modalTitle() {
      if(this.showType == 'add') {
        return '新增'
      }else if(this.showType == 'edit') {
        return '修改'
      }else if(this.showType == 'detail') {
        return '详情'
      }else {
        return '';
      }
    },
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.initDetail();
      }
    },
    modalVisible(val) {
      if(!val) {
        this.resetDatas();
        this.$emit('update:visible', false);
      }
    }
  },
  created() {
    this.modalVisible = this.visible;
    this.initLiftpropertyidOptions();
  },
  methods: {
    initLiftpropertyidOptions() {
      let params = {
        liftpropertytype: '1',
      }
      getLiftListByCondition(params).then(res => {
        if(res && res.returncode == '0') {
          this.liftpropertyidOptions = res.item;
        }
      })
    },
    initDetail() {
      if(this.detailData && (this.showType == 'edit' || this.showType == 'detail')) {
        this.$nextTick(() => {
          for(let key in this.formDatas) {
            this.formDatas[key] = this.detailData[key];
          }
          // this.formDatas = {
          //   ...this.formDatas,
          //   ...this.detailData
          // }
        })
      }
    },
    resetDatas() {
      this.formDatas.maintenanceitemid = '';
      this.$refs.modalForm.resetFields();
    },
    confirm() {
      // success fail
      if(this.showType == 'add' || this.showType == 'edit') {
        this.$refs.modalForm.validate(valid => {
          if(valid) {
            this.showLoading();
            let params = {
              ...this.formDatas
            };
            if(this.showType == 'add') {
              addMaintenanceitem(params).then(res => {
                this.hideLoading();
                if(res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                }else {
                  this.$message.error(res.errormsg||'操作失败');
                }
              })
            }else {
              modifyMaintenanceitem(params).then(res => {
                this.hideLoading();
                if(res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                }else {
                  this.$message.error(res.errormsg||'操作失败');
                }
              })
            }
          }else {
            return false;
          }
        })
      }else {
        this.modalVisible = false;
      }
    }
  }
}
</script>